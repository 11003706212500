:popover-open {
    max-width: $content-width * 0.75;
    padding: $spacing-unit;
    border-radius: 5px;
}

.popover-anchor {
    all: unset; /* Remove default styling */
    background-color: #FADF77;
    padding-left: $spacing-unit * 0.125;
    padding-right: $spacing-unit * 0.125;
}