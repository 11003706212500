@import "minima";

h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}


h4 {
  font-size: 18px;
}


body {
  font-family: 'Merriweather', serif;
}
