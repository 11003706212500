@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.75 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

.hn {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: inline;
  background-color: #ff6000;

  svg {
    height: .8em;
    width: .8em;
    vertical-align: baseline;
    outline: 1px solid white;
    margin-left: 5px;
  }

  a {
    color: #000000;
    text-decoration: none;
  }

  a:hover {
    color: #000000;
    text-decoration: none;
  }

  a:visited {
    color: #000000;
    text-decoration: none;
  }
}

// Can't use flexbox because Markdown wraps images in paragraph tags
// Idea credit: https://lazyren.github.io/devlog/how-to-center-an-image-in-markdown.html
img.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Ensure that paragraphs are flex elements so

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/popovers",
  "minima/syntax-highlighting"
;
